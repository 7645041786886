
import {
  Component, Prop, Vue,
} from 'nuxt-property-decorator'
import IImage from '../../shared/general/interfaces/IImage'
import { ILink } from '../../shared/general/interfaces/ILink'
import { TTeaserVariant } from '../../shared/general/types/TTeaserVariant'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'
import { openLightbox } from '../../shared/general/services/LightboxService'

@Component({
  name: 'BaseTeaser',
  components: {
    BaseLink: () => import('./BaseLink.vue'),
    BaseImageVideo: () => import('../ImageVideo/BaseImageVideo.vue'),
  },
})
export default class BaseTeaser extends Vue {
  @Prop() headline ?: string

  @Prop() text ?: string

  @Prop() image ?: IImage

  @Prop({ required: true }) link! : ILink

  @Prop({ default: 'vertical' }) teaserVariant! : TTeaserVariant

  @Prop({ default: '16:9' }) aspectRatio! : string

  @Prop({ default: false }) forceRatio! : boolean

  private get wrapperClasses () : string {
    const classes = {
      'horizontal-image-start-large': 'md:flex-row',
      'horizontal-image-end-large': 'md:flex-row-reverse',
    }
    return classes[this.teaserVariant]
  }

  private showLightbox (content : IImageVideoContent) : void {
    if (!['BaseVideo', 'BaseHtmlPlayer'].includes(content.tag)) return
    openLightbox(content)
  }
}
